import { FeatureFlags } from 'modules/featureFlags/model/featureFlags.model';
import { Nullable } from 'modules/shared/model';
import { NavigationItem } from 'utils/NavigationItems';

export const getAllowedNavigationItems = (
  items: NavigationItem[],
  featureFlags: Nullable<Record<FeatureFlags, boolean>>,
) => {
  return items.filter((item) => {
    if (item.label === 'Projects' && !featureFlags?.PROJECTS) return false;
    if (item.label === 'Workflows' && !featureFlags?.WORKFLOWS) return false;
    if (item.label === 'Timesheets' && !featureFlags?.TIMESHEETS) return false;
    if (item.label === 'Reports' && !featureFlags?.REPORTS) return false;

    if (item.submenu) {
      item.submenu = getAllowedNavigationItems(item.submenu, featureFlags);
    }

    return true;
  });
};
