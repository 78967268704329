import { type ChangeEvent, useState, useEffect } from 'react';
import { useQuery } from 'hooks';
import { setPassword } from '../../api/auth.api';
import classes from './Registration.module.scss';
import Header from '../Header/Header';
import InputContainer from '../InputContainer/InputContainer';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from 'utils/auth/tokenService';
import { Button, toast } from 'components/core';
import { mapErrorToErrorData } from 'utils';

type RegistrationFields = {
  password: string;
  repeatedPassword: string;
};

type FormValidity = {
  password: boolean;
  repeatedPassword: boolean;
};

const Registration = () => {
  const [isFormSubmitted, setIsFromSubmitted] = useState<boolean>(false);
  const [fields, setFields] = useState<RegistrationFields>({
    password: '',
    repeatedPassword: '',
  });
  const [formValidity, setFormValidity] = useState<FormValidity>({
    password: false,
    repeatedPassword: false,
  });

  const navigate = useNavigate();
  const token = useQuery().get('token') ?? '';

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFields((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    setFormValidity((prev) => ({
      ...prev,
      [event.target.name]: event.target.validity.valid,
    }));
  };

  const handleSubmit = async () => {
    setIsFromSubmitted(true);

    if (!formValidity.password || !formValidity.repeatedPassword) return;

    if (!isTokenValid(token)) {
      toast('error', 'Link is not valid, please generate new one.');
      return;
    }

    try {
      await setPassword({
        password: fields.password,
        repeatPassword: fields.repeatedPassword,
        token,
      });
      navigate('/');
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      if (errorData?.status === 401) {
        toast(
          'error',
          'Your registration token has expired. Please contact the administration to request a new registration link.',
        );
        return;
      }
      toast('error', errorData?.message || 'Something went wrong while setting the password.');
    }
  };

  useEffect(() => {
    if (!token || !isTokenValid(token)) navigate('/');
  }, [token, navigate]);

  return (
    <div className={classes['c-registration']}>
      <Header title="Set your account password" titleModifier="margin-bottom" />
      <InputContainer
        required
        label="Password"
        name="password"
        type="password"
        pattern=".{8,}"
        inputValidity={formValidity.password}
        invalidMessage="Password must be at least 8 characters long."
        isFormSubmitted={isFormSubmitted}
        handleInput={handleInput}
        handleOnKeyPressed={handleSubmit}
        setFormValidity={(validity) => {
          setFormValidity((prev) => ({ ...prev, password: validity }));
        }}
      />
      <InputContainer
        required
        label="Repeat password"
        name="repeatedPassword"
        type="password"
        pattern={fields.password}
        inputValidity={formValidity.repeatedPassword}
        invalidMessage="Passwords don't match, please try again."
        isFormSubmitted={isFormSubmitted}
        handleInput={handleInput}
        handleOnKeyPressed={handleSubmit}
        setFormValidity={(validity) => {
          setFormValidity((prev) => ({
            ...prev,
            repeatPassword: validity,
          }));
        }}
      />
      <div className={classes['c-registration__button-container']}>
        <Button size="large" onClick={handleSubmit}>
          Set password
        </Button>
      </div>
    </div>
  );
};

export default Registration;
