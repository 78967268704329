import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getAllTimeAwayTypes, bookTimeAway, getAllAvailableApprovers } from '../api/timeAway.api';
import { addNewTimeAway } from 'modules/timeline/redux/timelineSlice';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import type {
  BookTimeAwayInputs,
  NewTimeAway,
  TimeAwayApproveData,
  TimeAwayApprover,
  TimeAwayType,
} from '../models/TimeAway.model';
import { updateTimeAways } from 'modules/profile/redux/timeAwaysSlice';
import { Button, PopUpModal, DropDown, Input, DateRangePicker, toast } from 'components/core';
import axios, { AxiosError } from 'axios';
import { getUserFullName, formatDateForInput } from 'utils';
import classes from '../styles/BookTimeAwayModal.module.scss';
import dayjs from 'dayjs';
import { selectUser } from 'modules/profile/redux/userSlice';
import { getUserByOrganization } from 'modules/shared/redux/userActions';
import { convertEnumToString } from 'utils/stringUtils';

export type DateRangeType = {
  startDate?: Date;
  endDate?: Date;
  key?: string;
};

type Approver = Pick<TimeAwayApprover, 'id' | 'firstName' | 'lastName'>;

type Props = {
  opened: boolean;
  openModal: (value: boolean) => void;
};

const BookTimeAwayModal = ({ opened, openModal }: Props) => {
  const userId = useAppSelector((state) => state.auth.user?.id);
  const user = useAppSelector(selectUser);
  const [dateRange, setDateRange] = useState<DateRangeType[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [inputs, setInputs] = useState<BookTimeAwayInputs>({
    type: '',
    reason: '',
    note: '',
  });
  const [timeAwayTypes, setTimeAwayTypes] = useState<TimeAwayType[]>([]);
  const selectedStartPeriod = 'ALL_DAY';
  const selectedEndPeriod = 'ALL_DAY';
  const [selectedType, setSelectedType] = useState<TimeAwayType>({
    id: undefined,
    name: '',
  });
  const [selectedApprover, setSelectedApprover] = useState<Approver | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [areInputsValid, setAreInputsValid] = useState<boolean>(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [additionalApprovers, setAdditionalApprovers] = useState<TimeAwayApproveData | null>(null);

  const dispatch = useAppDispatch();

  const selectApproverError =
    !additionalApprovers?.approverAvailable && !selectedApprover && isFormSubmitted;

  const getData = async () => {
    try {
      const [timeAwayTypesResponse, approversResponse] = await Promise.all([
        getAllTimeAwayTypes(),
        getAllAvailableApprovers(),
      ]);

      setTimeAwayTypes(timeAwayTypesResponse.data);
      setAdditionalApprovers(approversResponse.data);
    } catch {
      toast('error', 'Something went wrong while getting the data. Please try again.');
    }
  };

  const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectedType = (selected: string) => {
    const selectedTimeAwayTypeName = selected.split('-')[0].trim();
    setSelectedType({
      name: selected,
      id: timeAwayTypes.find((item) => item.name === selectedTimeAwayTypeName)?.id,
    });
  };

  const handleSelectedApprover = (selectedUser: string) => {
    const selectedApprover = additionalApprovers?.users.find(
      ({ firstName, lastName }) => getUserFullName(firstName, lastName) === selectedUser,
    );

    if (!selectedApprover) return;

    const { id, firstName, lastName } = selectedApprover;

    setSelectedApprover({
      id,
      firstName,
      lastName,
    });
  };

  const renderTimeAwayTypesOptions = useCallback(() => {
    return timeAwayTypes.map(
      (item) => `${item.name} - ${convertEnumToString(item.timeAwayCategory!)}`,
    );
  }, [timeAwayTypes]);

  const handleOnCloseModal = useCallback(() => openModal(false), []);

  const handleBookTimeAway = async () => {
    setIsFormSubmitted(true);

    const newTimeAway: NewTimeAway = {
      startDate: formatDateForInput(dateRange[0].startDate ?? ''),
      endDate: formatDateForInput(dateRange[0].endDate ?? ''),
      timeAwayStartPeriod: selectedStartPeriod,
      timeAwayEndPeriod: selectedEndPeriod,
      approverId: selectedApprover?.id ?? null,
      note: inputs.note,
    };

    if (!areInputsValid || !selectedType.id) return;

    setIsLoading(true);

    try {
      const { data } = await bookTimeAway(selectedType.id, newTimeAway);
      dispatch(
        addNewTimeAway({
          timeAway: data,
          newUser: {
            userId: data.userId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            jobTitle: data.jobTitle,
            timeAways: [data],
          },
        }),
      );
      dispatch(updateTimeAways(data));
      openModal(false);
      toast('success', 'You have successfully booked time away.');
    } catch (error) {
      const { message } = error as AxiosError;
      let errorMessage = '';
      if (axios.isAxiosError(error) && error?.response?.data?.message)
        errorMessage = error.response.data.message;

      if (message.includes('Time away has been already booked for time period')) {
        toast('warning', message);
      } else if (errorMessage) {
        toast('warning', errorMessage);
      } else {
        toast('error', 'Something went wrong while booking time away. Try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dateRange[0].startDate && selectedType.id && inputs.note) {
      setAreInputsValid(true);
      return;
    }

    if (!additionalApprovers?.approverAvailable && !selectedApprover) {
      setAreInputsValid(false);
      return;
    }

    setAreInputsValid(false);
  }, [dateRange, selectedType, additionalApprovers, selectedApprover, inputs]);

  useEffect(() => {
    if (user.id !== userId) {
      dispatch(getUserByOrganization(String(userId)));
    }
  }, [dispatch, user, userId]);

  return (
    <PopUpModal title="Book time away" opened={opened} closeModal={handleOnCloseModal} width={30}>
      <div className={classes['c-book-time-away-modal__content']}>
        <div className={classes['c-book-time-away-modal__inputs-wrap']}>
          <DateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            openCalendar={openCalendar}
            onClick={setOpenCalendar}
            rangeColor="#2495ff"
            minDate={new Date()}
            maxDate={dayjs().add(1, 'year').toDate()}
            isSubmitted={isFormSubmitted}
            orientation="vertical"
            workingDays={user.employment.workingDays}
          ></DateRangePicker>
          <div>
            <span className={classes['c-book-time-away-modal__input-label']}>Reason</span>
            <DropDown
              options={renderTimeAwayTypesOptions()}
              selectedOption={selectedType?.name}
              setSelectedOption={handleSelectedType}
              isSubmitted={isFormSubmitted}
            />
          </div>
          <div>
            <span className={classes['c-book-time-away-modal__input-label']}>Note</span>
            <Input
              name="note"
              id="note"
              value={inputs.note}
              handleOnChange={handleInputs}
              isSubmitted={isFormSubmitted}
            />
          </div>
          {additionalApprovers && !additionalApprovers.approverAvailable && (
            <div>
              <span className={classes['c-book-time-away-modal__input-label']}>Approver</span>
              <DropDown
                options={
                  additionalApprovers.users?.map(({ firstName, lastName }) =>
                    getUserFullName(firstName, lastName),
                  ) ?? []
                }
                selectedOption={getUserFullName(
                  selectedApprover?.firstName ?? '',
                  selectedApprover?.lastName ?? '',
                )}
                setSelectedOption={handleSelectedApprover}
                isSubmitted={isFormSubmitted}
                error={selectApproverError ? 'Please select approver' : ''}
              />
            </div>
          )}
        </div>
        <Button size="medium" onClick={handleBookTimeAway} isLoading={isLoading}>
          Book time away
        </Button>
      </div>
    </PopUpModal>
  );
};

export default BookTimeAwayModal;
